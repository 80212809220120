import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponseCustom, GoogleLoginDto, LoginCredentials } from '@shared/interfaces/auth.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private storage: LocalStorageService) { }

  login(credentials: LoginCredentials): Observable<AuthResponseCustom> {
    return this.http.get<AuthResponseCustom>(
      environment.url + 'user-client/my-login?Ci=' +
      credentials.ci + '&Password=' +
      credentials.password
    );
  }

  loginWithGoogle(googleLoginDto: GoogleLoginDto){
    const url = `${environment.url}user-client/my-login-with-google?Ci=${googleLoginDto.ci}&GoogleId=${googleLoginDto.googleId}`;
    return this.http.get<AuthResponseCustom>(url);
  }

  checkEmail(email: string) {
    const url = `${environment.url}user-client/check-email?email=${email}`;
    return this.http.post(url, {});
  }

  logout(): void {
    this.storage.cleanStorage();
  }

}
