import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientInfoDto } from '@shared/interfaces/dtos/ClientInfoDto';
import { BenefitDto } from '@shared/interfaces/dtos/benefitDto';
import { LevelDto } from '@shared/interfaces/dtos/levelDto';
import { PurchaseDto } from '@shared/interfaces/dtos/purchaseDto';
import { GetUserShopClientDto, UserClientDto } from '@shared/interfaces/dtos/userClientDto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AutoPurchaseDto, AutoPurchaseScanDto } from '../../features/purchases/interfaces/purchase.interface';
import { RewardShopDto } from '@features/shops/interfaces/shop.interface';
import { ShopPointsPlanEnum } from '@features/points/interfaces/points.interface';
import { ChangeRewardsDto } from '@shared/interfaces/dtos/changeRewardsDto';

@Injectable({ providedIn: 'root' })
export class UserClientService {
  constructor(private httpClient: HttpClient) { }

  purchase(purchaseDto: PurchaseDto): Observable<object> {
    return this.httpClient.post(environment.url + 'user-client/purchase', purchaseDto);
  }

  createClient(userClientDto: UserClientDto) {
    return this.httpClient.post(environment.url + 'user-client/client', userClientDto);
  }

  getUserInfo(userDto: ClientInfoDto) {
    return this.httpClient.post(`${environment.url}user-client/validate-user-info`, userDto);
  }

  getClientsList() {
    return this.httpClient.get(environment.url + 'user-client/clients-list');
  }

  getPurchaseList() {
    return this.httpClient.get(environment.url + 'user-client/my-purchase');
  }

  getMyAccount(token: string) {
    return this.httpClient.get(environment.url + 'user-client/my-account?token=' + token);
  }

  updateAccount(userClientDto: UserClientDto): Observable<object> {
    return this.httpClient.put(environment.url + 'user-client/account', userClientDto);
  }

  getUserShopMetrics(token: string) {
    return this.httpClient.get(environment.url + 'user-client/user-shop-metrics?token=' + token);
  }

  getShopDetail(name: string) {
    return this.httpClient.get(environment.url + 'user-client/user-shop-detail?name=' + name);
  }

  createAccount(userClientDto: UserClientDto): Observable<object> {
    return this.httpClient.post(environment.url + 'user-client/account', userClientDto);
  }

  getPurchaseClientList(token: string) {
    return this.httpClient.get(environment.url + 'user-client/purchase-for-client?token=' + token);
  }

  getDiscount() {
    return this.httpClient.get(environment.url + 'user-client/discounts');
  }

  getAllDiscount() {
    return this.httpClient.get(environment.url + 'user-client/discounts-from-shops');
  }

  saveLevel(level: LevelDto) {
    return this.httpClient.put(environment.url + 'user-client/level', level);
  }

  getLevels() {
    return this.httpClient.get(environment.url + 'user-client/levels');
  }

  GetReport() {
    return this.httpClient.get(`${environment.url}user-client/export-excel`, {
      responseType: 'text',
      observe: 'response'
    });
  }

  createDiscount(benefitDto: BenefitDto) {
    return this.httpClient.post(environment.url + 'user-client/discount', benefitDto);
  }

  deleteDiscount(id: string) {
    return this.httpClient.delete(environment.url + 'user-client/' + id + '/discount');
  }

  GetMessageToSend(shopClientId: string) {
    return this.httpClient.get(environment.url + 'user-client/message-to-send/' + shopClientId,
      { responseType: 'text' });
  }

  GetShopMetrics(fromDays: number) {
    let url = 'shop-metrics'
    if (fromDays != undefined) {
      url += '?fromDays=' + fromDays.toString()
    }
    return this.httpClient.get(environment.url + 'user-client/' + url);
  }


  GetShopPrincipalMetrics(fromDays: number) {
    let url = 'shop-principal-metrics'
    if (fromDays != undefined) {
      url += '?fromDays=' + fromDays.toString()
    }
    return this.httpClient.get(environment.url + 'user-client/' + url);
  }

  GetShopLevelMetrics(fromDays: number) {
    let url = 'shop-level-metrics'
    if (fromDays != undefined) {
      url += '?fromDays=' + fromDays.toString()
    }
    return this.httpClient.get(environment.url + 'user-client/' + url);
  }

  sendPurchase(data: AutoPurchaseDto) {
    return this.httpClient.post(environment.url + 'user-client/auto-purchase', data);
  }

  sendScanPurchase(data: AutoPurchaseScanDto) {
    return this.httpClient.post(environment.url + 'user-client/auto-purchase-scan', data);
  }
  
  sendBillCheck(data: AutoPurchaseDto) {
    return this.httpClient.post(environment.url + 'user-client/check-bill', data);
  }
  

  generateUserShopClientMetrics( userShopClientDto: GetUserShopClientDto) {
    return this.httpClient.post(environment.url + 'user-client/generate-user-shop-client-metrics', userShopClientDto);
  }
  
  getFreeBenefitOnRegister(shopName: string){
    return this.httpClient.get(environment.url + 'user-client/benefit-free-register?shopName=' + shopName);
  }

  getShopDetailPage(shopName: string){
    return this.httpClient.get(environment.url + 'user-client/shop-detail?shopName=' + shopName);
  }

  forgotPassword(email: string) {
    return this.httpClient.post(
        `${environment.url}user-client/forgot-password`, 
        null, 
        { params: { email } }
    );
  }

  changeRewards(changeRewardsDto: ChangeRewardsDto){
    return this.httpClient.post(
      `${environment.url}user-client/changes-rewards`,
      changeRewardsDto
    );
  }
}
